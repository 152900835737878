<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('group_name')">
                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input v-model="formData.name"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{valid, errors}">
                            <b-input-group prepend="EN">
                                <b-form-input v-model="formData.name_en"
                                              :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm" :disabled="formLoading">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    // Services
    import ProgramGroupsService from '@/services/ProgramGroupsService';

    // Other
    import {ValidationObserver, ValidationProvider} from 'vee-validate'

    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            formId: {
                type: String
            }
        },
        data() {
            return {
                formLoading: false,
                formData: {
                    name: null,
                    name_en: null,
                },
                headOfProgramObject: {}
            }
        },
        async created() {
            await this.get(this.formId)
        },
        methods: {
            get(id) {
                ProgramGroupsService.get(id)
                              .then(response => {
                                  let data = response.data.data;
                                  this.formData = {
                                      name: data.name,
                                      name_en: data.name_en,
                                  }

                                  this.headOfProgramObject = {
                                      value: data.head_of_program,
                                      text: data.head_of_program_name
                                  }
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
            },

            async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate()
                if (isValid && this.formId) {
                    this.formLoading = true;
                    ProgramGroupsService.update(this.formId, this.formData)
                                  .then(response => {
                                      this.$emit('updateFormSuccess', true)
                                      this.$toast.success(this.$t('api.' + response.data.message))
                                  })
                                  .catch(e => {
                                      this.showErrors(e, this.$refs.formModalValidate)
                                  })
                                  .finally(() => {
                                      this.formLoading = false
                                  })
                }
            }
        }
    }
</script>
